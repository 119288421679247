<template>
  <div class="page-dashboard">
    <app-loading v-show="loadingData" />
    <v-container v-if="!loadingData">
      <partner />
    </v-container>
  </div>
</template>

<script>
import Material from 'vuetify/es5/util/colors'
import { mapActions, mapGetters, mapState } from 'vuex'
import Partner from '../../partners/partner/Partners'

export default {
  name: 'PartnerDashboard',
  components: { Partner },
  data () {
    return {
      tab: null,
      timer: '',
      loadingData: false,
      color: Material,
      statistic: [],
      statisticDeny: [],
      localSalesByPayments: [],
      localSalesByCategories: [],
      tabName: [
        {
          name: this.$vuetify.lang.t('$vuetify.dashboard.access'),
          icon: 'mdi-key',
          access: 'manager_access'
        },
        {
          name: this.$vuetify.lang.t('$vuetify.dashboard.info'),
          icon: 'mdi-info',
          access: 'manager_access'
        }
      ],
      trending: [
        {
          subheading: 'Email',
          headline: '15+',
          caption: 'email opens',
          percent: 15,
          icon: {
            label: 'email',
            color: 'info'
          },
          linear: {
            value: 15,
            color: 'info'
          }
        },
        {
          subheading: 'Tasks',
          headline: '90%',
          caption: 'tasks completed.',
          percent: 90,
          icon: {
            label: 'list',
            color: 'primary'
          },
          linear: {
            value: 90,
            color: 'success'
          }
        },
        {
          subheading: 'Issues',
          headline: '100%',
          caption: 'issues fixed.',
          percent: 100,
          icon: {
            label: 'bug_report',
            color: 'primary'
          },
          linear: {
            value: 100,
            color: 'error'
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('sale', ['salesByLimit', 'salesStatics']),
    ...mapState('article', ['articlesMerge']),
    ...mapGetters('auth', ['user', 'access_permit'])
  },
  watch: {
    access_permit: function () {
      this.completeStaticTic()
    }
  },
  async created () {
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
  }
}
</script>
